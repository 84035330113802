import React from 'react';
import { Link } from 'gatsby'

const CtaButton = ({ biggerTopMargin }) => {
  return (
    <Link to='/contact-us'>
      <button className="cta-button" style={biggerTopMargin ? {marginTop: '3rem'} : {}}>
        Contact Us!
      </button>
    </Link>
  )
}

export default CtaButton