import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import anime from 'animejs/lib/anime.es.js';
import PropTypes from 'prop-types';


import Layout from '../components/layout';
import SEO from '../components/seo';

const PhotoContainer = ({ photos, columns }) => {
  // Photos: graphql query of all photos and all markdown
  const { allImageSharp, allMarkdownRemark } = photos
  console.log(allImageSharp, allMarkdownRemark)
  return (
    <div style={{width:'100%'}}>
      <div className="flex-row-wrap flush-margins">
        {allMarkdownRemark.edges.map((edge, index) => {
          const imgFluidSrc = allImageSharp.edges.filter(
            x =>
              x.node.fluid.originalName ===
              edge.node.frontmatter.images[0]
          )[0].node.fluid;
          return (
            <div 
              key={index} 
              className="photo-container-photo" 
              style={{ 
                maxWidth: `${100 / columns }%`,
                flex: `1 0 ${100 / columns }%`,
              }}
            >
              <div className="photo-container-thumbnail">
                <Img fluid={imgFluidSrc} style={{maxHeight: 130}} imgStyle={{maxHeight: 130}}/>
                <div className="photo-container-photo-text">
                  {edge.node.frontmatter.title}
                </div>
              </div>
            </div>
          )
        })}

      </div>
    </div>
  )
}

PhotoContainer.defaultProps = {
  columns: 2,
}

export default PhotoContainer