import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

import PhotoContainer from '../components/photo-container';

const projectSpace = 40; // percentage that project panel should take up

const SplitView = ({ children, }) => {
  const projects = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 500) {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              path
              title
              miniDescription
              images
            }
          }
        }
      }
    }
  `)

  return (
    <div style={{ display: 'flex', width: '100%', maxWidth: '1230px', padding: '0px 2rem'}}>
      <div style={{flex: `1 0 ${100 - projectSpace}%`, width: `${100 - projectSpace}%`, paddingRight: '2.5rem' }}>
        {children}
      </div>
      <div style={{flex: `1 0 ${projectSpace}%`, width: `${projectSpace}%`}}>
        <Link to="/projects/" style={{color: 'black'}}>
          <h2>Completed Projects</h2>
        </Link>
        <PhotoContainer photos={projects} />
      </div>
    </div>
  )
}

export default SplitView