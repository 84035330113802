import React from 'react';
import Img from "gatsby-image"

import SEO from '../components/seo';
import Layout from '../components/layout';
import SplitView from '../components/splitview';
import CtaButton from '../components/cta-button';

const Services = ({ location }) => {
  return(
    <Layout location={location}>
      <SEO title="Services" />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <SplitView>
          <div className="services">
            <h1>Services</h1>
            <p>Every customer and project are different. However, every multi-family 
              development follows a similar road to completion. With Renno Specialists, 
              you can be confident that our wide variety of products and services ensures 
              that we will be able to support you, from start to finish.  </p>
            <p>Our sales and support teams are empowered to deliver tailored solutions 
              and a turnkey product.</p>
            <p>We are dedicated to multi-family, and have served the industry for 35 years.</p>
            <p>Renno Specialists enables our customers’ success by going beyond the sell 
              process to support the entire project.</p>
            <h3>Broad Product Range:</h3>
            <p>Renno Specialists features one of the largest ranges of products specifically 
              targeted to multi-family development on the market today.</p>
            <p>Owning our own factories gives us a step above the competition whereby increasing 
              the ability to meet the clients project budget goals.</p>
            <p>
              We offer cabinets in a variety of styles and species at a wide range of price points 
              to meet the needs of every project on any budget. </p>
            <p style={{marginBottom: 0}}>
              Additionally, Renno Specialists provides building materials such as:
            </p>
            <ul style={{ paddingLeft: '2.5rem'}}>
              <li>Flooring</li>
              <li>Lighting</li>
              <li>Plumbing Fixtures</li>
              <li>Hardware</li>
              <li>Cabinetry</li>
              <li>Countertops</li>
              <li>and more...</li>
            </ul>
            <CtaButton />
          </div>
        </SplitView>
      </div>
    </Layout>
  )
}

export default Services